import React from "react";
import "../App.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="disclaimer">
        <p>
          Disclaimer: While we strive to ensure the accuracy and timeliness of
          our information, we cannot guarantee that it will always be 100%
          up-to-date with the latest UI changes of social platforms. No data is
          sent to any server; all processing occurs directly on your device.
        </p>
      </div>
      <div className="footer-links">
        <p>
          <a
            href="https://paia-intelligence.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <span className="separator">•</span>
          <a
            href="https://paia-intelligence.com/imprint"
            target="_blank"
            rel="noopener noreferrer"
          >
            Imprint
          </a>
          <span className="separator">•</span>
          <a
            href="https://www.laria.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            © Safezone-Check {currentYear} by laria
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
